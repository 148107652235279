import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'serial_numbers',
    initialState: {serial_numbers: [], serial_numbers_single: false, message: false},
    reducers:{
        getSerialNumbers: (state,action)=>{
            state.serial_numbers = action.payload
        },
        getOneSerialNumber: (state,action)=>{
            state.serial_numbers = action.payload
        },
        saveSerialNumber: (state,action)=>{
            state.serial_numbers.unshift(action.payload)
            state.message = true
        },
        editSerialNumber: (state,action)=>{
            state.serial_numbers_single = false
            state.message = true
        },
        deleteToSerialNumber: (state,action)=>{
            state.serial_numbers = state.serial_numbers.filter(item=>item.id !== action.payload.id)
            state.message = 'deleted'
        },
        messageReset: (state,action)=>{
            state.message = false
        },
        messageFail: (state,action)=>{
            state.message = 'error'
        }
    }
})

export const getAlLSerialNumbers = () => apiCall({
    url: 'serial-numbers/',
    method: 'GET',
    onSuccess: slice.actions.getSerialNumbers.type
})

export default slice.reducer
export const {messageReset} = slice.actions