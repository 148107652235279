import * as React from "react";
import Box from "@mui/material/Box";
import {useNavigate, useParams} from "react-router-dom";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import scan_img from '../scan.png'
import audio_err from '../warn.mp3'
import audio_finish from '../finish.mp3'
import {editSaveDocument, getAlLThis, getDocument, messageReset} from "../store/documents";
import {getAllVerifyResponse} from "../store/logIn";
import Dialog from '@mui/material/Dialog';
import {Slide, toast} from "react-toastify";

function EditDocument(props) {
    let audio = new Audio(audio_err)
    let finish = new Audio(audio_finish)
    const navigate = useNavigate()
    const params = useParams()
    const [code, setCode] = useState('')
    const [thisOpen, setThisOpen] = useState(false)
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.setOpen(false)
        props.setOpenItem(false)
    };

    useEffect(() => {
        if (props.open) {
            setOpen(props.open)
        }
    }, [props])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (code && props.setOpenItem){
                const data = {
                    "serial_number": props.openItem['part'],
                    "code": props.openItem['code'],
                    "track_code": code,
                }
                props.editSaveDocument(data)
                setThisOpen(true)
                setCode('')
            }
        }, 50)

        return () => clearTimeout(delayDebounceFn)
    }, [code])

    useEffect(() => {
        const docs = props.documents.filter(item => item.place === 'china')
        if (!docs.length && thisOpen) {
            finish.play()
            toast.success("Barchasi muvaffaqiyatli saqlandi!",
                {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                })
            props.messageReset()
            props.setOpen(false)
        } else if(!docs.length){
            props.setOpen(false)
        }
    }, [props.documents]);


    useEffect(() => {
        const docs = props.documents.filter(item => item.place === 'china')
        if (props.message === true && docs.length){
            const data = {
                "part": params['part'],
                "code": params['code']
            }
            props.getAlLThis(data)
            props.messageReset()
        } else if (props.message === 'error') {
            audio.play()
            toast.error("Xatolik! Trek kod to'g'ri kelmadi!", {
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            })
            props.messageReset()
            props.setOpen(false)
        }
    }, [props.message])


    return <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                }}>
                <div style={{padding:'20px 20px 30px 20px', overflow: "hidden"}}>
                    <h3 className={'page-title text-center'}>{"Iltimos Scan Qiling!"}</h3>
                    <div className={'scan-block'}>
                        <div className={"fingerprint scanning"}>
                            <img src={scan_img} alt="Scan"/>
                        </div>
                    </div>
                    <TextField
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                        required
                        className={"inp-text"}
                        onBlur={e => e.target.focus()}
                        type={"text"}
                        autoFocus={true}
                        variant="outlined"
                    />
                </div>
            </Box>
        </Dialog>
    </div>
}

export default connect(props => props.documents, {
    getDocument,
    editSaveDocument,
    getAllVerifyResponse,
    getAlLThis,
    messageReset
})(EditDocument)