import {connect} from "react-redux";
import {getAlLDocuments, messageReset} from "../store/documents";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import {FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Box from "@mui/material/Box";
import Copyright from "../blocks/copyright";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Documents(props) {
    const navigate = useNavigate()
    const params = useParams()
    const [scan, setScan] = useState(1)
    const [documents, setDocuments] = useState([])
    const [scanTrack, setScanTrack] = useState([])
    const [filteredDocuments, setFilteredDocuments] = useState(false)
    const [searchWord, setSearchWord] = useState('')

    useEffect(() => {
        props.getAlLDocuments(params['str'])
        const scan_val = JSON.parse(localStorage.getItem('scan'));
        if(scan_val){
            setScan(scan_val)
        }
    }, [])


    useEffect(() => {
        const arrayUniqueByKey = [...new Map(props.documents.map(item =>
            [item['code'], item])).values()];
        setDocuments(arrayUniqueByKey)

        let result = Object.values(props.documents.reduce((hash, item) => {
            if (!hash[item.code]) {
                hash[item.code] = {code: item.code, uz: [], china: []};
            }
            if (item.place == 'uz') {
                hash[item.code].uz.push(item.place);
            } else {
                hash[item.code].china.push(item.place);
            }
            return hash;
        }, {}));
        setScanTrack(result)
    }, [props])

    useEffect(() => {
        localStorage.setItem("scan", JSON.stringify(scan));
    }, [scan]);


    // Pagination

    let pageItems = filteredDocuments ? filteredDocuments : documents

    useEffect(() => {
        pageItems = filteredDocuments ? filteredDocuments : documents
    }, [filteredDocuments])

    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = pageItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(pageItems.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % pageItems.length;
        setItemOffset(newOffset);
    };

    if (pageCount !== 0 && currentItems.length === 0) {
        let li_lenght = document.querySelectorAll('.pagination ul li').length
        document.querySelectorAll('.pagination ul li')[li_lenght - 2].previousSibling.className = 'selected'
        const data = {"selected": pageCount - 1}
        handlePageClick(data)
    }

    function toEdit(e, data) {
        e.currentTarget.blur()
        navigate('/one-doc/' + params['str'] + '/' + data['code'])
    }

    function goBack(e) {
        e.preventDefault()
        navigate('/serial-numbers/')
    }

    function getScanResult(code) {
        for (let i = 0; i < scanTrack.length; i++) {
            if (code == scanTrack[i]['code']) {
                return scanTrack[i]['uz'].length
            }
        }
    }

    function getNoScanResult(code) {
        for (let i = 0; i < scanTrack.length; i++) {
            if (code == scanTrack[i]['code']) {
                return scanTrack[i]['china'].length
            }
        }
    }

    useEffect(() => {
        let filterDocuments = searchWord ? true : false
        if (scan == 'china') {
            filterDocuments = filterDocuments ? props.documents.filter(item => (item.code.toLowerCase().search(searchWord.toLowerCase()) !== -1)).filter(item => item.place == scan)
                : props.documents.filter(item => item.place == scan)
            const arrayUniqueByKey = [...new Map(filterDocuments.map(item =>
                [item['code'], item])).values()];
            setFilteredDocuments([...arrayUniqueByKey])
        }
        if (scan == 'uz') {
            filterDocuments = filterDocuments ? props.documents.filter(item => (item.code.toLowerCase().search(searchWord.toLowerCase()) !== -1)).filter(item => item.place == scan)
                : props.documents.filter(item => item.place == scan)
            const arrayUniqueByKey = [...new Map(filterDocuments.map(item =>
                [item['code'], item])).values()];
            setFilteredDocuments([...arrayUniqueByKey])
        }
        if (scan == '1' && !searchWord) {
            setFilteredDocuments(false)
        } else if (scan == '1' && searchWord){
            filterDocuments = props.documents.filter(item => (item.code.toLowerCase().search(searchWord.toLowerCase()) !== -1))
            const arrayUniqueByKey = [...new Map(filterDocuments.map(item =>
                [item['code'], item])).values()];
            setFilteredDocuments([...arrayUniqueByKey])
        }
    }, [scan, searchWord, props.documents]);

    function handleKeyDown(e){
        if (e.key === 'Enter') {
            if (filteredDocuments.length) {
                navigate('/one-doc/' + params['str'] + '/' + filteredDocuments[0]['code'])
            }
        }
    }

    function ToFilter(e) {
        e.preventDefault()
        if (!e.target.value && scan == '1') {
            setFilteredDocuments(false)
        } else {
            let filterDocuments = scan !== 1 ? true : false
            if (e.target.value && filterDocuments) {
                const search_word = e.target.value
                filterDocuments = filterDocuments ? props.documents.filter(item =>
                        (item.code.toLowerCase().search(search_word.toLowerCase()) !== -1)).filter(item => item.place == scan)
                    : props.documents.filter(item =>
                        (item.code.toLowerCase().search(search_word.toLowerCase()) !== -1))
                const arrayUniqueByKey = [...new Map(filterDocuments.map(item =>
                    [item['code'], item])).values()];
                setFilteredDocuments([...arrayUniqueByKey])
            } else if (e.target.value) {
                const search_word = e.target.value
                filterDocuments = props.documents.filter(item => (item.code.toLowerCase().search(search_word.toLowerCase()) !== -1))
                const arrayUniqueByKey = [...new Map(filterDocuments.map(item =>
                    [item['code'], item])).values()];
                setFilteredDocuments([...arrayUniqueByKey])
            }
        }
    }

    return <div>
        <div className={'page worker-page documents'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <Button variant="outlined" startIcon={<ArrowBackIcon/>} onClick={(e) => goBack(e)}>
                        Ortga
                    </Button>
                    <div className={'header-page'}>
                        <h2 className={'page-title'}>{"Buyurtmalar"}</h2>
                    </div>
                    <div className={'filter-block'}>
                        <form onChange={ToFilter} onSubmit={ToFilter}>
                            <p><span>{"Izlash"}:</span>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">{"Izlash"}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        autoFocus
                                        onKeyDown={handleKeyDown}
                                        value={searchWord}
                                        onChange={(e) => setSearchWord(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle search"
                                                    edge="end"
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={"Izlash"}
                                    />
                                </FormControl>
                            </p>
                            <p><span>{"Buyurtmalar"}:</span>
                                <Box sx={{minWidth: 200}}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="demo-simple-select-label">{"Filter"}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={scan}
                                            label={"Filter"}
                                            onChange={(e) => {
                                                setScan(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={1}>{"Barchasi"}</MenuItem>
                                            <MenuItem value={'uz'}>{"Scan qilingan"}</MenuItem>
                                            <MenuItem value={'china'}>{"Scan qilinmagan"}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box></p>
                        </form>
                    </div>
                    {currentItems.length ? <table className="table table-hover table-responsive ">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{"Buyurtma kodi"}</th>
                            <th scope="col">{"Partiya raqami"}</th>
                            <th scope="col">{"Scan qilingan"}</th>
                            <th scope="col">{"Scan qilinmagan"}</th>
                            <th scope="col">{"Scan qilish"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.length ? currentItems.map((item, index) => <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item.code}</td>
                                <td>{item.serial_number}</td>
                                <td>{getScanResult(item.code)}</td>
                                <td>{getNoScanResult(item.code)}</td>
                                <td>
                                    <Button variant="contained" title={"O'zgartirish"} color={"success"}
                                            endIcon={<DocumentScannerIcon/>}
                                            onClick={(e) => toEdit(e, item)}/>
                                </td>
                            </tr>)
                            :
                            ''
                        }
                        </tbody>
                    </table> : ''}
                    {pageCount > 1 ? <div className={'pagination'}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<KeyboardArrowRightIcon/>}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={<KeyboardArrowLeftIcon/>}
                            renderOnZeroPageCount={null}
                        />
                    </div> : ''}
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.documents, {getAlLDocuments, getAllVerifyResponse, messageReset})(Documents)