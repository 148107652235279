import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Copyright from "../blocks/copyright";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {documentSave, getAlLDocuments, messageReset} from "../store/documents";
import {getAllVerifyResponse} from "../store/logIn";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";

function DocumentAdd(props){
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const [document_title, setDocumentTitle] = useState('')
    const [document_file, setDocumentFile] = useState('')

    function saveFormData(e) {
        e.preventDefault()
        const files = e.target[2].files
        if (!files[0]){
            setDocumentFile('*')
        }else{
            const data = {
                "title": document_title,
                "file": files[0],
            }
            props.documentSave(data)
        }
    }
    function prepareUpload(event){
        var files = event.target.files;
        setDocumentFile(files[0].name)
    }

    useEffect(() => {
        if (props.message === true) {
            setDocumentFile('')
            setDocumentFile('')
            navigate('/documents/')
        } else if (props.message === 'error') {
            toast.error(t('save_error'), {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])

    return <div>
        <div className={'page'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <h2 className={'page-title text-center'}>{t('add_document')}</h2>
                    <form className={'add-form add-document'} onSubmit={saveFormData} encType={'multipart/form-data'}>
                        <p>
                            <span>{t('document_title')}:</span>
                            <TextField
                                label={t('document_title')}
                                value={document_title}
                                onChange={(e) => setDocumentTitle(e.target.value)}
                                required
                                type={"text"}
                                variant="outlined"
                            />
                        </p>
                        <p>
                            <span className={document_file === '*' ? 'text-danger' : ''}>{t('take_file')}: {document_file} </span>
                            <Button className={'file-upload-btn'} variant="contained" component="label">
                                {t('take_file')}
                                <input name={'document_file'} hidden multiple type="file"
                                       onChange={prepareUpload} title={t('take_file')}/>
                            </Button>
                        </p>
                        <Button type={'submit'} style={{width: '100%'}} variant="contained"
                                fullWidth color={'info'}>
                            {t('save')}
                        </Button>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}
export default connect(props => props.documents, {documentSave, getAllVerifyResponse, messageReset})(DocumentAdd)