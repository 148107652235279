import {connect} from "react-redux";
import {getAlLThis} from "../store/documents";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Box from "@mui/material/Box";
import Copyright from "../blocks/copyright";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EditDocument from "./editDocument";
import uzb_flag from "../uzbekistan.png"
import china_flag from "../chinas.png"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScanDocument from "../blocks/scanDocument";


function OneDoc(props) {

    const navigate = useNavigate()
    const params = useParams()
    const [documents, setDocuments] = useState([])
    const [open, setOpen] = useState(false)
    const [openScanNow, setOpenScanNow] = useState(false)
    const [scanTrack, setScanTrack] = useState([])
    const [notFound, setNotFound] = useState(false)
    const [openItem, setOpenItem] = useState(false)
    const [openScanNowItem, setOpenScanNowItem] = useState(false)

    useEffect(() => {
        const data = {
            "part": params['part'],
            "code": params['code']
        }
        props.getAlLThis(data)
        document.addEventListener("keydown", KeyCheck);  //or however you are calling your method
        function KeyCheck(event) {
            var KeyID = event.keyCode;
            if (KeyID===8) {
                navigate('/documents/' + params['part'])
            }
        }
    }, [])


    useEffect(() => {
        const data = {
            "part": params['part'],
            "code": params['code']
        }
        props.getAlLThis(data)
    }, [open, openScanNow])


    useEffect(() => {
        const this_doc = props.documents.filter(item => item.code !== params['code'])
        if (!this_doc.length) {
            setDocuments(props.documents)
        }
        const docs = props.documents.filter(item => item.place === 'china')
        if (!docs.length) {
            setNotFound(true)
        }
        let result = Object.values(props.documents.reduce((hash, item) => {
            if (!hash[item.code]) {
                hash[item.code] = {code: item.code, uz: [], china: []};
            }
            if (item.place == 'uz') {
                hash[item.code].uz.push(item.place);
            } else {
                hash[item.code].china.push(item.place);
            }
            return hash;
        }, {}));
        setScanTrack(result)
    }, [props])

    useEffect(() => {
        const docs = props.documents.filter(item => item.code === params['code']).filter(item => item.place === 'china')
        if (docs.length) {
            setOpen(true)
            const data = {
                "part": params['part'],
                "code": params['code']
            }
            setOpenItem(data)
        }
    }, [notFound]);

    // Pagination

    let pageItems = documents

    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = pageItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(pageItems.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % pageItems.length;
        setItemOffset(newOffset);
    };

    if (pageCount !== 0 && currentItems.length === 0) {
        let li_lenght = document.querySelectorAll('.pagination ul li').length
        document.querySelectorAll('.pagination ul li')[li_lenght - 2].previousSibling.className = 'selected'
        const data = {"selected": pageCount - 1}
        handlePageClick(data)
    }

    function toEdit(e) {
        e.currentTarget.blur()
        setOpen(true)
        const data = {
            "part": params['part'],
            "code": params['code']
        }
        setOpenItem(data)
    }

    function toScan(e, track_code) {
        e.currentTarget.blur()
        setOpenScanNow(true)
        const data = {
            "track_code": track_code,
            "part": params['part'],
            "code": params['code']
        }
        setOpenScanNowItem(data)
    }

    function getScanResult(code) {
        for (let i = 0; i < scanTrack.length; i++) {
            if (code == scanTrack[i]['code']) {
                return scanTrack[i]['uz'].length
            }
        }
    }

    function getNoScanResult(code) {
        for (let i = 0; i < scanTrack.length; i++) {
            if (code == scanTrack[i]['code']) {
                return scanTrack[i]['china'].length
            }
        }
    }

    function goBack(e) {
        e.preventDefault()
        navigate('/documents/' + params['part'])
    }

    function sortScanned() {
        const strDescending = [...documents].sort((a, b) =>
            a.place > b.place ? -1 : 1,
        );
        setDocuments(strDescending)
    }

    function sortNotScanned() {
        const strAscending = [...documents].sort((a, b) =>
            a.place > b.place ? 1 : -1,
        );
        setDocuments(strAscending)
    }


    return <div>
        <div className={'page worker-page documents'}>
            {open ? <EditDocument setOpenItem={setOpenItem} openItem={openItem} open={open} setOpen={setOpen}/> : ''}
            {openScanNow ? <ScanDocument setOpenItem={setOpenScanNowItem} openItem={openScanNowItem} open={openScanNow}
                                         setOpen={setOpenScanNow}/> : ''}
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <Button variant="outlined" startIcon={<ArrowBackIcon/>} onClick={(e) => goBack(e)}>
                        Ortga
                    </Button>
                    <div className={'header-page'}>
                        <h2 className={'page-title'}>{"Scan qilish"}</h2>
                        <p>
                            <h5 style={{cursor: "pointer"}} onClick={() => sortScanned()}>Scan
                                qilingan: {getScanResult(props.documents.length ? props.documents[0]['code'] : 0)}</h5>
                            <h5 style={{cursor: "pointer"}} onClick={() => sortNotScanned()}>Scan
                                qilinmagan: {getNoScanResult(props.documents.length ? props.documents[0]['code'] : 0)}</h5>
                        </p>

                    </div>
                    <div className={'filter-block scan'}>
                        {notFound ? '' :
                            <Button variant="contained" title={"O'zgartirish"} color={"success"}
                                    endIcon={<DocumentScannerIcon/>}
                                    onClick={(e) => toEdit(e)}>Scan qilish</Button>}
                    </div>
                    {currentItems.length ? <table className="table table-hover table-responsive ">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{"Buyurtma kodi"}</th>
                            <th scope="col">{"Partiya raqami"}</th>
                            <th scope="col">{"Trek kod"}</th>
                            <th scope="col">{"Sana"}</th>
                            <th scope="col">{"Joylashgan joyi"}</th>
                            {notFound ? '' : <th scope="col">{"Scan qilish"}</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.length ? currentItems.map((item, index) => <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item.code}</td>
                                <td>{item.serial_number}</td>
                                <td>{item.track_code}</td>
                                <td>{item.create_date.split("-").reverse().join(".")}</td>
                                <td><img src={item.place == 'uz' ? uzb_flag : china_flag} className={"flag"} alt="Flag"/>
                                    {item.place === 'uz' ? "O'zbekiston" : "Xitoy"}</td>
                                {notFound || item.place === 'uz' ? '' :
                                    <td><Button variant="contained" title={"O'zgartirish"} color={"success"}
                                                endIcon={<DocumentScannerIcon/>}
                                                onClick={(e) => toScan(e, item.track_code)}/></td>}

                            </tr>)
                            :
                            ''
                        }
                        </tbody>
                    </table> : ''}
                    {pageCount > 1 ? <div className={'pagination'}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<KeyboardArrowRightIcon/>}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={<KeyboardArrowLeftIcon/>}
                            renderOnZeroPageCount={null}
                        />
                    </div> : ''}
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.documents, {getAlLThis, getAllVerifyResponse})(OneDoc)