import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


function MainListItems(props){
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    const [isHome, setIsHome] = useState(false)
    const [serial_numbers, setSerialNumbers] = useState(false)

    function changeValues(val){
        switch (val){
            case 1:
                navigate('/dashboard/')
                break;
            case 2:
                navigate('/serial-numbers/')
                break;
            default:
                return false
        }
    }

    useEffect(()=>{
        if(window.location.pathname === '/dashboard/'){
            setIsHome(true)
            setSerialNumbers(false)
        }else if(window.location.pathname === '/serial-numbers/'){
            setSerialNumbers(true)
            setIsHome(false)
        }
    }, [])



    return <div className={'menu'}>
        <ListItemButton className={isHome ? 'active' : ''}  onClick={()=>changeValues(1)} >
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={"Bosh sahifa"}/>
        </ListItemButton>
        <ListItemButton className={serial_numbers ? 'active' : ''}  onClick={()=>changeValues(2)} >
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary={"Buyurtmalar"}/>
        </ListItemButton>
    </div>
}

export default MainListItems
