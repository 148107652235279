import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {getAllVerifyResponse, getUserInfo} from "../store/logIn";

function Header(props){
    const navigate = useNavigate()

    useEffect(()=>{
        const token = localStorage.getItem('token');
        if (token !== 'undefined'){
            const data = {
                "token": JSON.parse(token)
            }
            props.getAllVerifyResponse(data)
        }
        props.getUserInfo()
    }, [])

    useEffect(()=>{
        if (props.verify===false){
            navigate("/login/");
        }
        localStorage.setItem('is_main', JSON.stringify(true));
    }, [props])

    return <div>
        <div className="loader"/>
    </div>
}
export default connect(props=>props.login, {getAllVerifyResponse, getUserInfo})(Header)