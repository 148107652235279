import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./pages/loginPage";
import Documents from "./pages/documents";
import DocumentAdd from "./pages/documentAdd";
import EditDocument from "./pages/editDocument";
import SerialNumber from "./pages/serialNumber";
import OneDoc from "./pages/oneDoc";
import NotFound from "./pages/notfound";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Suspense fallback={'Loading...'}>
            <Provider store={store}>
                <Routes>
                    <Route path={'/'} element={<App/>}/>
                    <Route path={'/dashboard/'} element={<App/>}/>
                    <Route path={'/login/'} element={<SignIn/>}/>
                    <Route path={'/documents/:str'} element={<Documents/>}/>
                    <Route path={'/one-doc/:part/:code'} element={<OneDoc/>}/>
                    <Route path={'/serial-numbers/'} element={<SerialNumber/>}/>
                    <Route path={'/add-document/'} element={<DocumentAdd/>}/>
                    <Route path={'/edit-document/:id'} element={<EditDocument/>}/>
                    <Route path={"*"} element={<NotFound/>} />
                </Routes>
            </Provider>
        </Suspense>
    </BrowserRouter>
);

reportWebVitals();
