import {configureStore} from '@reduxjs/toolkit'
import {api} from "./middleware/api";
import documents from "./documents"
import serial_numbers from "./serial_numbers"
import login from "./logIn"


export default configureStore({
    reducer: {
        documents, login, serial_numbers
    },
    middleware: [
        api,
    ]
})