import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAllVerifyResponse} from "../store/logIn";
import {FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import Box from "@mui/material/Box";
import Copyright from "../blocks/copyright";
import ReactPaginate from "react-paginate";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {toast} from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {getAlLSerialNumbers, messageReset} from "../store/serial_numbers";

function SerialNumbers(props) {
    const is_main = JSON.parse(localStorage.getItem('is_main'));
    const navigate = useNavigate()
    const [serial_numbers, setSerialNumbers] = useState([])
    const [filteredSerialNumbers, setFilteredSerialNumbers] = useState(false)
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (props.message === 'deleted') {
            toast.success("Success", {theme: 'colored'})
            props.messageReset()
        } else if (props.message === 'error') {
            toast.error("Error", {theme: 'colored'})
            props.messageReset()
        } else if (props.message === true) {
            toast.success("Success", {theme: 'colored'})
            props.messageReset()
        }
    }, [props.message])


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token !== 'undefined') {
            const data = {
                "token": JSON.parse(token)
            }
            props.getAllVerifyResponse(data)
        }
        props.getAlLSerialNumbers()
    }, [])


    useEffect(() => {
        if (props.verify === false) {
            navigate("/login/");
        }
        if (props.serial_numbers.serial_numbers){
            setSerialNumbers(props.serial_numbers.serial_numbers)
        }
    }, [props])


    // Pagination
    const pageItems = filteredSerialNumbers ? filteredSerialNumbers : serial_numbers
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = pageItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(pageItems.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % pageItems.length;
        setItemOffset(newOffset);
    };

    if (pageCount !== 0 && currentItems.length === 0) {
        let li_lenght = document.querySelectorAll('.pagination ul li').length
        document.querySelectorAll('.pagination ul li')[li_lenght - 2].previousSibling.className = 'selected'
        const data = {"selected": pageCount - 1}
        handlePageClick(data)
    }

    function toEdit(data) {
        navigate('/documents/' + data)
        localStorage.setItem("scan", JSON.stringify('1'));
    }

    function ToFilter(e) {
        e.preventDefault()
        if (!e.target.value) {
            setFilteredSerialNumbers(false)
        } else {
            let filterDocuments = false
            if (e.target.value && filterDocuments) {
                const search_word = e.target.value
                filterDocuments = filterDocuments.filter(item => (item.toLowerCase().search(search_word.toLowerCase()) !== -1))
                setFilteredSerialNumbers([...filterDocuments])
            } else if (e.target.value) {
                const search_word = e.target.value
                filterDocuments = serial_numbers.filter(item => (item.toLowerCase().search(search_word.toLowerCase()) !== -1))
                setFilteredSerialNumbers([...filterDocuments])
            }
        }
    }


    return <div>
        <div className={'page worker-page serial_numbers'}>
            <Header/>
            <Dashboard/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}>
                <Toolbar/>
                <div className={'main'}>
                    <div className={'header-page'}>
                        <h2 className={'page-title'}>{"Partiyalar"}</h2>
                    </div>
                    <div className={'filter-block'}>
                        <form onChange={ToFilter}>
                            <p><span>{"Izlash"}:</span>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">{"Izlash"}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle search"
                                                    edge="end"
                                                    value={search}
                                                    onChange={(event) => {
                                                        setSearch(event.target.value)
                                                    }}
                                                >
                                                    <SearchIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={"Izlash"}
                                    />
                                </FormControl>
                            </p>
                        </form>
                    </div>
                    {currentItems.length ? <table className="table table-hover table-responsive ">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{"Partiya raqami"}</th>
                            <th scope="col">{"Tegishli buyurtmalar"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.length ? currentItems.map((item, index) => <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item}</td>
                                <td>
                                    <Button variant="contained" title={"Tegishli buyurtmalar"} color={"info"}
                                            endIcon={<ArrowForwardIcon/>}
                                            onClick={()=>toEdit(item)}/>
                                </td>
                            </tr>)
                            :
                            ''
                        }
                        </tbody>
                    </table> : ''}
                    {pageCount > 1 ? <div className={'pagination'}>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={<KeyboardArrowRightIcon/>}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={<KeyboardArrowLeftIcon/>}
                            renderOnZeroPageCount={null}
                        />
                    </div> : ''}
                </div>
                <Copyright/>
            </Box>
        </div>
    </div>
}

export default connect(props => props.serial_numbers, {getAlLSerialNumbers, getAllVerifyResponse, messageReset})(SerialNumbers)