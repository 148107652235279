import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";
import {ToastContainer} from "react-toastify";

export default function Copyright(props) {
    return (
        <Typography className={'copyright'} variant="body2" color="text.secondary" align="center" {...props}>
            <Link color="#0a58ca" href="https://t.me/Avtokargo_tolov_bot">
                Avto kargo tolov bot
            </Link>
            <p>{'Copyright © '}{new Date().getFullYear()}</p>
            <ToastContainer/>
        </Typography>
    );
}