import './App.css';
import Header from "./blocks/header";
import Dashboard from "./blocks/dashboard";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Copyright from "./blocks/copyright";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {Link} from "react-router-dom";


function App() {

  return (
      <div className="App">
        <div className={'home page'}>
          <Header/>
          <Dashboard/>
          <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}>
            <Toolbar/>
            <div className={'main'}>
              <div className="row">
                <div className="col-lg-3">
                  <Link to={'/serial-numbers/'}>
                    <div className="card">
                      <div className="card-header">
                        <WidgetsIcon className={'icon-card'}/>
                      </div>
                      <div className="card-body">
                        <h3 className={'text-center mb-0 text-uppercase'}>Buyurtmalar</h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <Copyright/>
          </Box>
        </div>
      </div>
  );
}

export default App;