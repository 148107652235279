import {createSlice} from "@reduxjs/toolkit";
import {apiCall} from "./apiAction";

const slice = createSlice({
    name: 'login',
    initialState: {login: {}, error: false, verify: false, user: {}},
    reducers:{
        getResponse: (state,action)=>{
            state.login = action.payload
            state.error = false
            state.verify = true
        },
        getResponseFail: (state,action)=>{
            state.error = true
            state.login = {}
            state.verify = false
        },
        getVerifyResponse: (state,action)=>{
            state.login = action.payload
            state.verify = true
            state.error = false
        },
        getVerifyResponseFail: (state,action)=>{
            state.verify = false
            state.login = {}
        },
        getUserResponse: (state,action)=>{
            state.user = action.payload[0]
        },
    }
})

export const getAllResponse = (data) => apiCall({
    url: 'login/',
    method: 'POST',
    data: data,
    onSuccess: slice.actions.getResponse.type,
    onFail: slice.actions.getResponseFail.type,
})

export const getAllVerifyResponse = (data) => apiCall({
    url: 'token/verify/',
    method: 'POST',
    data: data,
    onSuccess: slice.actions.getVerifyResponse.type,
    onFail: slice.actions.getVerifyResponseFail.type,
})

export const getUserInfo = () => apiCall({
    url: 'users/',
    method: 'GET',
    onSuccess: slice.actions.getUserResponse.type,
})

export default slice.reducer