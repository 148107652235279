import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import Dialog from '@mui/material/Dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from "@mui/material/Button";
import {editSaveDocument, getAlLThis, getDocument, messageReset} from "../store/documents";
import {Slide, toast} from "react-toastify";
import audio_finish from '../finish.mp3'
import {getAllVerifyResponse} from "../store/logIn";

function ScanDocument(props){
    let finish = new Audio(audio_finish)
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.setOpen(false)
        props.setOpenItem(false)
    };

    function toDelete(){
        const data = {
            "serial_number": props.openItem['part'],
            "code": props.openItem['code'],
            "track_code": props.openItem['track_code'],
        }
        props.editSaveDocument(data)
    }

    useEffect(()=>{
        if(props.open){
            setOpen(props.open)
        }
    }, [props])

    useEffect(() => {
        if (props.message == true) {
            finish.play()
            toast.success("Muvaffaqiyatli saqlandi!",
                {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                })

            props.messageReset()
            props.setOpen(false)
            props.setOpenItem(false)
        }
    }, [props.message]);


    return <div>
        <Dialog
            open={open}
            className={"scanNow"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className={'delete-modal'}>
                <div className="icon">
                    <HighlightOffIcon color={'error'}/>
                </div>
                <h2>{"Tasdiqlash"}</h2>
                <p>{"Ha tugmasini bosish orqali ushbu buyurtmani joylashgan joyi avtomatik tarzda o'zgaradi va qayta o'zgartira olmaysiz"}</p>
                <div className="buttons">
                    <Button variant="contained" className={'cancel-btn'} onClick={handleClose}>
                        {"Yo'q ortga qaytish"}
                    </Button>
                    <Button variant="contained" className={'delete-btn'} onClick={toDelete}>
                        {"Ha o'zgartirish"}
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
}
export default connect(props => props.documents, {
    getDocument,
    editSaveDocument,
    getAllVerifyResponse,
    getAlLThis,
    messageReset
})(ScanDocument)