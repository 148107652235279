import Header from "../blocks/header";
import Dashboard from "../blocks/dashboard";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Copyright from "../blocks/copyright";
import Box from "@mui/material/Box";

function NotFound(){
    return <div className={'page'}>
        <Header/>
        <Dashboard/>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}>
            <Toolbar/>
            <div className={'main'}>
                <div className="page notFound">
                    <h2>{"Hech qanday ma'lumot topilmadi!"}</h2>
                </div>
            </div>
            <Copyright/>
        </Box>

    </div>
}
export default NotFound